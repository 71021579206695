import { PAGA_BANK_DETAILS } from "../actions/types";

export const pagaBankDetails = (state = null, { type, payload }) => {
    switch (type) {
        case PAGA_BANK_DETAILS:
            console.log("----bankdetails reducer----", payload)
            state = payload.status ? [payload] : [];
            return payload;
        default:
            return state;
    }
}