import { PAGA_AUTO_SWEEP_BANK_DETAILS } from "../actions/types";

export const autoSweepBankList = (state = [], { type, payload }) => {
    switch (type) {
        case PAGA_AUTO_SWEEP_BANK_DETAILS:
            state = payload.status ? [payload.data.banks] : [];
            return payload.data;
        default:
            return state;
    }
}