import { PAGA_BANK_LIST, PAGA_SEND_MONEY_SECURITY_VERIFICATION } from "../actions/types";

const verificationInitialState = null;

export const securityVerify = (state = verificationInitialState, { type, payload }) => {
    switch (type) {
        case PAGA_SEND_MONEY_SECURITY_VERIFICATION:
            state = payload.status ? [payload] : [];
            return payload;
        default:
            return state;
    }
}

export const banksList = (state = [], { type, payload }) => {
    switch (type) {
        case PAGA_BANK_LIST:
            state = payload.status ? [payload.data.banks] : [];
            return payload.data;
        default:
            return state;
    }
}