import { combineReducers } from "redux";
import authReducer from "./auth";
import errorReducer from "./errorReducer";
import dashboardReducer from "./dashboardReducer";
import {
  newDashboardReducer,
  checkStoreActivationOnDevice,
} from "./newDashboardReducer";
import roomReducer from "./roomReducer";
import itemReducer, { getHsnCode } from "./itemReducers";
import categoryReducer from "./categoryReducers";
import brandReducer from "./brandReducers";
import combos from "./comboReducers";
import vouchers, { fetchGiftVoucher } from "./voucherReducers";
import orderDetailReducer from "./orderDetailReducers";
import taxReducer from "./taxReducer";
import chargesReducer from "./chargesReducer";
import { discounts, prodVariants } from "./discountReducers";
import modifiersReducer from "./modifierReducers";
import historyState from "./historyReducer";
import {
  getInventory,
  stockFormData,
  setCartData,
  getUnitsList,
  getPurchaseOrders,
  getPurchaseOrdersDetail,
  getDetails,
  getVendorsListing,
  stockTransactionHistory,
  stockTransaction,
  stockRequestHistory,
  getStockRequisitionsWeb,
  remarks,
  setValidProds,
  vendorInvoices,
  getInvoicePaymentInfo,
  productsChain,
  batches,
  batchProds,
  getBatchProductsList,
  localityOrders,
  chainEcomStores,
  localityMapping,
  dispatchOrderStatus,
  doSummary,
  doDetail,
  stockProductsListChain,
  isFirstTimeSetup,
  sourceVendorsList,
  vendorProductMapping,
  exportProductsListChainWeb,
  productsListTrimmedWeb,
  productsListTrimmedWebWithCombos,
  getStoreCatalogueList,
  getBatchesWebReducer,
  getChainStoreWareReducer,
  productsChainPromotion,
  fetchPaymentValues
} from "./inventoryReducer";
import { allProdsList, allProdsDetailsList,reservationListData, filterGroupList, reservationFilterList } from "./masterDataReducers";
import { getTableLayoutWeb } from "./floorReducers";
import { incomeHeads } from "./IncomeHeadReducer";
import {
  Services,
  treatmentTypes,
  categoriesForProd,
  serviceGroupCategories,
  getChainTaxes,
  getChainCharges,
  editService,
  serviceSlotsData,
} from "./servicesReducers";
import {
  getProductsBatchVariantsWeb,
  getCatalogueListWeb,
  getDeleteAllItem,
} from "./productGroupReducers";
import { addUnit, editUnit, units } from "./unitReducers";
import { membershipsWeb, getSubCategories } from "./membershipReducers";
import { initLoader } from "./loaderReducer";
import { initNotifier } from "./notifierReducer";
import { nonStoreProds, fetchStorePrices } from "./manageStoreReducers";
import { sizes, colors } from "./size&colorReducers";
import {
  fetchRegions,
  printReceiptSettingsInfo,
  chainBillingInfo,
  chainInfo,
  storeInfo,
  chainStoreList,
  getDevices,
  getDevicesLogs,
  customers,
  loyaltyAllProducts,
  loyaltyInfo,
  loyaltyProducts,
  chainLicenses,
  messageSettingsInfo,
  smsRatesInfo,
  appSettings,
  getWarehouses,
  attributes,
  allAttributes,
  savePaymentInfo,
  checkLicensePromo,
  getIndustryListWeb,
  GetAppointmentsDetails,
  GetUserDetails,
  GetCityDetails,
  GetSyncTypeData,
  fetchSystemAttributes,
  getCounterList,
  salesAssessmentPeriods,
  salesTagetConfigs,
  soucreWiseUsers,
  getStoreWiseAllProds
} from "./setupReducers";
import {
  users,
  roles,
  setNewMobileOtp,
  setNewMobile,
  checkUsername,
  updateBasicInfoWeb,
} from "./usercustomerReducers";
import {
  purchaseOrdersList,
  poVendorsList,
  poStoreList,
  poWareList,
  products,
  poUnits,
  poTaxes,
  selectedDateRange,
  confirmedProducts,
  modalShow,
  PODetail,
} from "./poReducers";
import PIReducers from "./PIReducers";
import OrdersReducers from "./OrdersReducers";
import SalesReducer from "./salesReducers";
import DWReducer from "./dwReducers";
import customersReducers from "./customersReducers";
import usersReducers from "./usersReducer";
import { setLanguage } from "./helperReducers";
import uiReducers from "./uiReducers";
import { getTaxProds, chargeProds } from "./taxReducer";
import {
  banners,
  getOnlineStoreProds,
  getReservationSettings,
  getOnlineChainSettings,
  getOnlineStoreSettings,
  fetchStoreCatalogue,
  getOnlineOrderCreditInfo,
  getCreditPackageReducer,
} from "./estoreReducers";
import inventoryRptReducers, { productsInPODetails, LowStockProducts } from './inventoryRptReducers';
import {getModifiersProductWeb,getProductCharges,productModifiers} from './modifierReducers';
import {checkEmail, checkOtpWeb, forgotPasswordUpdateWeb, signUpWeb} from './credentialReducers';
import manufacturersReducer from './manufacturersReducer';
import { getAllChargesReportsWeb, getCumulativeCollectionDataWeb, getWebMuncipleOerderDetailsWeb } from './muncipalReportsReducer';
import  {ProdTransactionTracker}  from "./salesReducers"
import {billingCatalogueReducer, billingCheckConnection, billingGetAppSettings, billingHoldOrderReducer, billingShortcuts} from "./BillingReducer";
import {billingCustomerReducer} from "./BillingReducer";
import {billingCartReducer} from "./BillingReducer";
import { urbanChainStoreSettings, urbanStoreSettings, urbanPiperApiLogs, urbanPiperStoreProds } from "./urbanPiperReducer";
import { listOfBundles } from "./bundleReducer";
import { fetchPriceListData, getAllChainCustomersData } from "./priceListReducer";
import { fetchExpenseCatergory } from "./eoReducers";
import { securityVerify, banksList } from "./sendMoneyReducer";
import { pagaBankDetails } from "./accountBalanceReducer";
import { autoSweepBankList } from "./autoSweepReducer";


const rootReducer = combineReducers({
    auth: authReducer,
    errors: errorReducer,
    dashboard: dashboardReducer,
    newDashboard: newDashboardReducer,
    checkStoreActivationOnDevice:checkStoreActivationOnDevice,
    credentialEmail: checkEmail,
    credentialOtp : checkOtpWeb,
    credentialPassword : forgotPasswordUpdateWeb,
    credentialSignUp : signUpWeb,
    vendorsSourceList : sourceVendorsList,
    productVendorMapping: vendorProductMapping,
    room: roomReducer,
    items: itemReducer,
    catList: categoryReducer,
    brandList: brandReducer,
    manufacturersList: manufacturersReducer,
    orderDetailInfo: orderDetailReducer,
    allProds: allProdsList,
    allProdsDetails: allProdsDetailsList,
    reservationDetails : reservationListData ,
    combosList: combos,
    taxes: taxReducer,
    charges: chargesReducer,
    vouchersList: vouchers,
    discountsListData: discounts,
    prodVariantListData: prodVariants,
    modifiers: modifiersReducer,
    inventoryData: getInventory,
    stockFormData: stockFormData,
    setCartData: setCartData,
    unitsList: getUnitsList,
    // createPoOrder: createPurchaseOrder,
    purchaseOrder: getPurchaseOrders,
    purchaseOrdersDetail: getPurchaseOrdersDetail,
    requisitionOrdersDetail: getDetails,
    vendorsList: getVendorsListing,
    stockHistory: stockTransactionHistory,
    stockReqHistory: stockRequestHistory,
    transactionResponse: stockTransaction,
    remarksList: remarks,
    setValidProdsState: setValidProds,
    invoices: vendorInvoices,
    invoicePaymentInfo: getInvoicePaymentInfo,
    tableData: getTableLayoutWeb,
    incomeHeadsList: incomeHeads,
    productsByChain: productsChain,
    productsListTrimmedByChain: productsListTrimmedWeb,
    productsListTrimmedWithCombosByChain: productsListTrimmedWebWithCombos,
    productsByChainExcel:exportProductsListChainWeb,
    productsByChainPromotion: productsChainPromotion,
    fetchPaymentValues: fetchPaymentValues,
    isFirstTimeSetup,
    servicesList: Services,
    editService,
    treatmentTypesList: treatmentTypes,
    serviceGroupsList: categoriesForProd,
    serviceGroupCategoryList: serviceGroupCategories,
    taxesChainList: getChainTaxes,
    chargesChainList: getChainCharges,
    prodBatchVariantsList: getProductsBatchVariantsWeb,
    prodCatalogueList: getCatalogueListWeb,
    getDeleteAllItems : getDeleteAllItem,
    addUnitStatus: addUnit,
    addUnitStatus: editUnit,
    baseUnitList: units,
    getMembershipsStatus: membershipsWeb,
    loaderStatus: initLoader,
    notifierStatus: initNotifier,
    subCategoryList: getSubCategories,
    nonStoreProdsStatus: nonStoreProds,
    sizesListData: sizes,
    colorsListData: colors,
    getRegionsStatus: fetchRegions,
    getPrintSettingStatus: printReceiptSettingsInfo,
    getChainInfoStatus: chainInfo,
    getChainBillInfoStatus: chainBillingInfo,
    getStoreInfoStatus: storeInfo,
    getChainStoreListInfoStatus: chainStoreList,
    getSmsSettingStatus: messageSettingsInfo,
    getSmsRatesStatus: smsRatesInfo,
    getAppSettingStatus: getDevices,
    getDeviceLogStatus: getDevicesLogs,
    getPrinterTerminalStatus: appSettings,
    getInventoryBatchStatus: batches,
    getBatchProducts: batchProds,
    batchProductList: getBatchProductsList,
    localityOrders,
    chainEcomStores,
    localityMapping,
    dispatchOrderStatus,
    doSummary,
    doDetail,
    purchaseOrdersList, 
    poVendorsList, 
    poStoreList, 
    poWareList, 
    products, 
    poUnits, 
    poTaxes, 
    selectedDateRange, 
    confirmedProducts, 
    modalShow, 
    PODetail,
    // ...POReduces,
    ...PIReducers,
    ...OrdersReducers,
    ...SalesReducer,
    ...DWReducer,
    ...inventoryRptReducers,
    ...customersReducers,
    ...usersReducers,
    getCustomersStatus: customers,
    getLoyaltyInfoStatus: loyaltyInfo,
    getLoyaltyProductsStatus: loyaltyProducts,
    getConfigLoyaltyProductsStatus: loyaltyAllProducts,
    getChainLiscensesStatus: chainLicenses,
    getRequisitionIds: getStockRequisitionsWeb,
    getUsersStatus: users,
    getRolesStatus: roles,
    getUsernameAvailStatus: checkUsername,
    getUpdateBasicInfoWeb: updateBasicInfoWeb,
    languageStatus: setLanguage,
    ...uiReducers,
    getOtpVerifyStatus:setNewMobileOtp,
    getWarehouseInfoStatus:getWarehouses,
    getAttributeStatus:attributes,
    getSystemAttributeStatus:fetchSystemAttributes,
    getAllAttributesStatus:allAttributes,
    getTaxProdsStatus:getTaxProds,
    getChargeProdsStatus:chargeProds,
   productStorePriceStatus:fetchStorePrices,
   getBannersStatus:banners,
   getOnlineStoreProdsStatus:getOnlineStoreProds,
   getReservationSettingsStatus:getReservationSettings,
   getOnlineChainSettingsStatus:getOnlineChainSettings,
   getOnlineStoreSettingsStatus:getOnlineStoreSettings,
   getModifiersProductWebStatus:getModifiersProductWeb,
   stockProductsListChainStatus:stockProductsListChain,
   getProductChargesStatus:getProductCharges,
   setNewMobileStatus:setNewMobile,
   estoreProductStorePriceStatus:fetchStoreCatalogue,
   getOnlineOrderCreditInfo:getOnlineOrderCreditInfo,
   getCreditPackage:getCreditPackageReducer,
   getLicensePromoStatus:checkLicensePromo,
   getPaymentInfoStatus:savePaymentInfo,
   getIndustryListStatus: getIndustryListWeb,
   getStoreCatalogueList: getStoreCatalogueList,
   getBatchesWebReducer:getBatchesWebReducer,
   getAllChargesReportsWeb:getAllChargesReportsWeb,
   getCumulativeCollectionDataWeb:getCumulativeCollectionDataWeb,
   getWebMuncipleOerderDetailsWeb:getWebMuncipleOerderDetailsWeb,
   GetAppointmentsDetails:GetAppointmentsDetails,
   GetUserDetails:GetUserDetails,
   productsInPODetails:productsInPODetails,
   LowStockProducts:LowStockProducts,
   ProdTransactionTracker:ProdTransactionTracker,
   GetCityDetails:GetCityDetails,
   GetSyncTypeData:GetSyncTypeData,
   billingCatalogue:billingCatalogueReducer,
   billingCart:billingCartReducer,
   billingCustomer:billingCustomerReducer,
   billingHoldOrders:billingHoldOrderReducer,
   billingAppSettings:billingGetAppSettings,
   billingCheckConnection:billingCheckConnection,
   billingShortcuts:billingShortcuts,
   getCounterListData:getCounterList,
   getProductModifiersStatus:productModifiers,
   chainStoreSeetingsUrban:urbanChainStoreSettings,
   storeSettingsUrbanPiper:urbanStoreSettings,
   upProdsDataStatus:urbanPiperStoreProds,
   urbanPiperApiLogs:urbanPiperApiLogs,
   listOfBundles:listOfBundles,
   salesAssessmentPeriods:salesAssessmentPeriods,
   salesTagetConfigs:salesTagetConfigs,
   getChainStoreWareList : getChainStoreWareReducer,
   soucreWiseUsers:soucreWiseUsers,
   fetchGiftVoucherStatus:fetchGiftVoucher,
   getListOfPriceList:fetchPriceListData,
   getAllChainCustomers:getAllChainCustomersData,
   storeWiseAllProd:getStoreWiseAllProds,
   historyState,
   hsnList:getHsnCode,
   fetchExpenseCatergory : fetchExpenseCatergory,
   filterGroupList,
   reservationFilterList,
   serviceSlotsData,
   securityVerificationDetail: securityVerify,
   banksListPAGA: banksList,
   pagaBankDetails: pagaBankDetails,
   pagaAutoSweepBanks: autoSweepBankList
}); 

export default (state, action) =>
  rootReducer(action.type === 'LOGOUT' ? undefined : state, action);
